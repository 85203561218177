/* eslint-disable global-require */
/* eslint-disable no-param-reassign */
let element: HTMLDivElement;
export function decodeHTMLEntities(str: string | null): string | null {
    // this prevents any overhead from creating the object each time
    if (str && typeof str === 'string') {
        if (typeof window !== 'undefined') {
            if (!element) {
                element = document.createElement('div');
            }

            // strip script/html tags
            str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, '');
            str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, '');
            element.innerHTML = str;
            str = element.textContent;
            element.textContent = '';
        } else {
            // Use he only in server to reduce bundle size in client
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            const he = require('he');
            str = he.decode(str);
            str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, '');
            str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, '');
        }
    }
    return str;
}
