import { useEffect, useState } from 'react';

export default function useBreadcrumbInfo(categories) {
    const [breadcrumbInfo, setBreadcrumbInfo] = useState([]);

    useEffect(() => {
        const node = categories?.edges[0]?.node;
        const ancestorNodes = node?.ancestors?.nodes;
        let breadcrumbArray = [];
        if (ancestorNodes) {
            const nodesCopy = [...ancestorNodes];
            const reverseNodes = nodesCopy.reverse();
            for (let reverseNode of reverseNodes) {
                const { name, uri } = reverseNode;
                breadcrumbArray.push({ name, uri });
            }
        }
        breadcrumbArray.push({ name: node?.name, uri: node?.uri });
        setBreadcrumbInfo(breadcrumbArray);
    }, [categories]);

    return breadcrumbInfo;
}
